import React, { Component } from 'react'
import FrameAccess from './FrameAccess'
import { NavLink, Navbar, NavbarBrand } from 'react-bootstrap';

export default class LoadAccess extends Component {
    render() {
        return (
            <>
                <div className='App-header'>

                    <FrameAccess />
                </div>
            </>
        )
    }
}
