import React, { useState, useEffect, useImperativeHandle, forwardRef, ref } from 'react';
import { Button } from 'react-bootstrap';
import { useMercadopago } from 'react-sdk-mercadopago';
import axios from 'axios';

const MercadoPagoBtn = forwardRef(({ nameId, ssuid, metaObject, totalCost, nameApp, accessToken , publicKey}, ref) => {
  const [takeId, setTakeId] = useState("");
  const [titleCard, setTitleCard] = useState(nameId);
  const [unitCts, setUnitCts] = useState(totalCost);
  const [codeId, setCodeId] = useState(ssuid);
  const [nameApps, setNameApp] = useState(nameApp);

  const mercadopago = useMercadopago.v2(publicKey, {
    locale: 'es-MX'
  });

  useEffect(() => {
    if (!takeId) return;
    if (mercadopago) {
      console.log(takeId);
      mercadopago.checkout({
        preference: {
          id: takeId
        },
        autoOpen: true,
      });
    }
  }, [mercadopago, takeId]);
  const handlePay = async () => {
    try {   
      const response = await axios.post('http://localhost:3001/create_preference', {
        access_token: accessToken,  
        sandbox: true,
        quantity: 1,
        description: titleCard,
        price: unitCts + 4 + (0.031 * unitCts),
        codeTraker: codeId,
        nameAppx: nameApps
      });
      setTakeId(response.data.id);
    } catch (error) {
      try {
        const response = await axios.post('https://misistemita-c3aa2441c528.herokuapp.com/create_preference', {
          access_token: accessToken, 
          sandbox: true,
          quantity: 1,
          description: titleCard,
          price: unitCts + 4 + (0.031 * unitCts),
          codeTraker: codeId,
          nameAppx: nameApps
        });
        setTakeId(response.data.id);
      } catch (error) {
        console.error("Error al hacer la solicitud:", error);
      }
    }
  };

useImperativeHandle(ref, () => ({
  triggerPayment: handlePay
}));

return (
  <>
    <div className='no-show'>
      <Button variant="outline-info" onClick={handlePay} size="lg">Mercadopago</Button>
    </div>
    <div className="chk-btn-pay">
    </div>
  </>
);
});

export default MercadoPagoBtn;