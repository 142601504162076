import React, { Component } from 'react'
import { CardBody, CardTitle, Col, Row, Card } from 'react-bootstrap'

export default class Tutoriales extends Component {
  render() {
    return (
      <div className='App-header'>
        <header >
          <h2>Tutoriales</h2>
        </header>
        <div className='w-50'>
          <div className='App-header'>
            <Row >
              <Col>
                <Card>
                  <CardTitle>Tu tienda online en menos de 10 min</CardTitle>
                  <CardBody>
                   

                  </CardBody>
                </Card>
              </Col>
              <Col>
              </Col>
            </Row>
          </div>
        </div>
        <footer>
          <Row>
            <Col>
              <p>Mi sistemita cuenta con una demostracion con la cual podras adaptarte a sus mecanicas</p>
              <p><a href="https://misistemita.store/lasollas" >https://misistemita.store/lasollas</a></p>
            </Col>
          </Row>
          <h4></h4>
        </footer>

        <p>

        </p>
        <br />
        <p>

        </p>
      </div>
    )
  }
}
