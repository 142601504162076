import React, { Component } from 'react';
import { Card, Row, Col } from 'react-bootstrap';

export default class FrameAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AccessMap: [
        { a: 'Standar', b: 'Duracion de 1 año', c: 'Pagos mediante MercadoPago', d: 'Edicion en marketing y +', e: 'DDSN', f: '$5,722.45' },
        { a: 'Comanda', b: 'Duracion de 3 años', c: 'Menu de FX', d: 'Calendario', e: 'Menu de CyberComanda y +', f: '$17,493.31' },
        { a: 'Basica', b: 'Duracion de 1 año', c: 'Incluye tu negocio a la nube ', d: 'Administracion sencilla', e: 'caracteristicas exclucivas', f: '$889.37' },
      ],
      starCount: 100, 
    };
  }

  renderStars = () => {
    const { starCount } = this.state;
    const starsArray = [];
    for (let i = 0; i < starCount; i++) {
      const size = Math.random() * 3 + 1; 
      const style = {
        left: `${Math.random() * 100}vw`,
        width: `${size}px`,
        height: `${size}px`,
        animationDuration: `${Math.random() * 5 + 5}s`, 
        animationDelay: `${Math.random() * 5}s`, 
      };
      starsArray.push(<div key={i} className="star" style={style}></div>);
    }
    return starsArray;
  };

  render() {
    return (
      <div className='container'>
        <div className="stars">
          {this.renderStars()}
        </div>
        <Row>
          {this.state.AccessMap.map((s, index) => (
            <Col key={index} sm={4}>
              <Card className="glass-card">
                <Card.Header><h5>{s.a}</h5></Card.Header>
                <Card.Body>
                  <p>{s.b}</p>
                  <p>{s.c}</p>
                  <p>{s.d}</p>
                  <p>{s.e}</p>
                </Card.Body>
                <Card.Footer><strong>{s.f}</strong></Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}
