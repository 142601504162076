import React, { Component } from 'react';
import { Nav, CardBody, Col, NavLink, Navbar, NavbarBrand, Row } from 'react-bootstrap';
import { LoadHomePage } from '../store/MetaStore';
import LoadHome from './LoadHome';
import LoadAccess from './LoadAccess';
import Tutoriales from './Tutoriales';


export default class Home extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = LoadHomePage.init;
  }

  componentDidMount() {
    const { pathname } = window.location;
    return pathname.startsWith('/Accesos') ? this.setState({ hrefOs: 'accesos' }) : pathname.startsWith('/') ? this.setState({ hrefOs: 'home' }) : <></>
  }



  render() {
    return (
      <div className='App'>
        <Navbar  expand="lg">
          <NavbarBrand className='rounded-font' onClick={() => this.setState({ hrefOs: 'home' })}>
            Mi sistemita
          </NavbarBrand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <NavLink onClick={() => this.setState({ hrefOs: 'tutoriales' })}>Tutoriales</NavLink>
              <NavLink onClick={() => this.setState({ hrefOs: 'accesos' })}>
                Accesos
              </NavLink>
              <NavLink href="/Creatuapp">Crear App</NavLink>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="led-background nav" style={{height:'2px'}}></div>
        {this.state.hrefOs === 'home' ? <LoadHome /> : <></>}
        {this.state.hrefOs === 'tutoriales' ? <Tutoriales /> : <></>}
        {this.state.hrefOs === 'accesos' ? <LoadAccess /> : <></>}
        <div className="led-background nav" style={{height:'1px'}}></div>
      </div>
    );
  }
}

