import React, { Component } from 'react'
import { LoadProduction } from '../../store/MetaStore';
import { Card, CardBody, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import db from '../../firebase';

export default class Cique extends Component {

    constructor(props){
        super(props)
        this.state = LoadProduction.init
    }
    
    cleanPathname() {
        const { pathname } = window.location;
        return pathname.startsWith('/admin/') ? pathname.substring('/admin/'.length) : pathname;
    }
    
    async componentDidMount() {
        const cleanedPathname = this.cleanPathname();
        const license = doc(db, '$:__sites-name', cleanedPathname);

        this.setState({ nameSite: cleanedPathname });

        if (cleanedPathname) {
            const isLicenseAvailable = await getDoc(license);
            if (isLicenseAvailable.exists()) {
                this.setState({
                    ...isLicenseAvailable.data(),
                    pageExist: true,
                });
            } else {
                setTimeout(() => {
                    alert('Este sitio no existe');
                    window.location = '/';
                }, 1500);
            }
        }
    }
    
    cique(c) {
        if (this.props.cique) {
            this.props.cique(c);     
        } else {
            console.error("cique is not a function");
        }
    }


  render() {
    return (
        <CardBody>
        <InputGroup>
            <FormControl
                placeholder='Password'
                type='password'
                onChange={(e) => {this.cique(e.target.value) }}
            />
        </InputGroup>
    </CardBody>
    )
  }
}
