import { doc, getDoc, setDoc, updateDoc, collection, getDocs, deleteDoc, } from 'firebase/firestore';
import React, { Component } from 'react';
import { Card, Col, Form, Modal, Row, Button, Offcanvas, CardGroup } from 'react-bootstrap';
import db from '../firebase';
import { LoadTicketCreation } from '../store/MetaStore';

export default class LoadMonitorFrame extends Component {
  constructor(props) {
    super(props);
    this.state = LoadTicketCreation.init;
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }

  componentDidMount = async () => {
    const { pathname } = window.location;
    const cleanedPathname = pathname.startsWith('/monitor/') ? pathname.substring('/monitor/'.length) : pathname;

    const license = await doc(db, '$:__sites-name', cleanedPathname)
    if (cleanedPathname !== '') {
      const isLicenseAvalible = await getDoc(license)
      this.state = isLicenseAvalible.data()
      isLicenseAvalible.exists() ?
        this.setState({
          ...isLicenseAvalible.data(),
          pageExist: true
        }, async () => {
          const ticketsSnapshot = await getDocs(collection(db, '$:__site-tickets'));
          const filteredTickets = [];

          ticketsSnapshot.forEach((doc) => {
            const ticketData = doc.data();
            if (ticketData.siteName === this.state.siteName) {
              filteredTickets.push({ id: doc.id, ...ticketData });
            }
          });
          this.setState({ filteredTickets });
          console.log(filteredTickets)
        }, () => { console.log(this.state) })
        :
        setTimeout(() => {
          alert('este sitio no existe'); window.location = '/'
        }, 3500);
    }
  }

  componentWillUnmount = () => {
    this.clearUpdateInterval();
  }

  getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'pendiente a pago':
        return 'yellow';
      case 'cancelado':
        return 'red';
      case 'error':
        return 'red';
      case 'pagado':
        return 'green';
      case 'completado':
        return 'blue';
      case 'preparando':
        return 'orange';
      default:
        return 'black';
    }
  }

  startInterval = () => {

    this.intervalId = setInterval(async () => {

      const ticketsSnapshot = await getDocs(collection(db, '$:__site-tickets'));
      const filteredTickets = [];
      ticketsSnapshot.forEach((doc) => {
        const ticketData = doc.data();
        if (ticketData.siteName === this.state.siteName) {
          filteredTickets.push({ id: doc.id, ...ticketData });
        }
      });
      this.setState({ filteredTickets });

    }, 2500);
  };

  clearUpdateInterval = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  };

  handleModalStatus = (ticket) => {
    this.clearUpdateInterval();
    this.setState({
      modalStatusTicket: true,
      selectedTicket: ticket,
      pointStatusTicket: ticket.status,
    });
  }

  handleCloseModal = () => {
    this.setState({
      modalStatusTicket: false,
      selectedTicket: null,
      pointStatusTicket: '',
      frameConfirmClose: false,
    }, () => {
      if (!this.state.modalStatusTicket) {
        this.startInterval();
      }
    });
  }

  async handleChangeStatus(event) {
    console.log(this.state)
    const newStatus = event.target.value;
    console.log(newStatus)
    console.log(this.state)
    const ticketId = this.state.selectedTicket.idTicket;
    const currentDateTime = new Date().toLocaleString();
    this.setState(prevState => {
      const updatedLog = [
        ...prevState.pointItemLog,
        {
          ticketId: ticketId,
          status: newStatus,
          date: currentDateTime
        }
      ];
      return {
        pointMonitor: {
          ...prevState.pointMonitor,
          ticketsNow: {
            ...prevState.ticketNow,
            state: newStatus
          },
        },
        pointStatusTicket: newStatus,
        pointItemLog: updatedLog,
      };
    });

    if (event.target.value === "cerrar") {
      this.setState({
        secureState: true,
        frameConfirmClose: true,
      });
      console.log('cerrar');
    }
  }

  handleCloseOffcanvas = () => {
    this.setState({
      frameConfirmClose: false,
      modalStatusTicket: false,
      secureState: false,
    });
  }
  handleConfirmCloseTicket = async () => {
    const ticketId = this.state.selectedTicket.idTicket;
    const currentDateTime = new Date().toLocaleString();

    // Busca el ticket en `filteredTickets`
    const ticketParent = this.state.filteredTickets.find(obj => 
        obj.pointMonitor.ticketsNow.some(ticket => ticket.idTicket === ticketId)
    );

    if (ticketParent) {
        const ticketToClose = ticketParent.pointMonitor.ticketsNow.find(ticket => ticket.idTicket === ticketId);

        if (ticketToClose) {
            const updatedTicketsNow = ticketParent.pointMonitor.ticketsNow.filter(ticket => ticket.idTicket !== ticketId);
            const updatedLog = [
                ...this.state.pointItemLog,
                {
                    ticketId: ticketId,
                    status: 'cerrar',
                    date: currentDateTime
                }
            ];

            // Actualiza el estado local
            this.setState(prevState => ({
                filteredTickets: prevState.filteredTickets.map(obj =>
                    obj === ticketParent
                        ? {
                              ...obj,
                              pointMonitor: {
                                  ...obj.pointMonitor,
                                  ticketsNow: updatedTicketsNow
                              }
                          }
                        : obj
                ),
                pointItemLog: updatedLog,
                frameConfirmClose: false,
                modalStatusTicket: false,
                secureState: false,
            }), async () => {
                try {
                    // Elimina el ticket de `'$:__site-tickets'`
                    await deleteDoc(doc(db, '$:__site-tickets', ticketId));

                    // Guarda el ticket con datos adicionales en `'$:__site-close'`
                    await setDoc(doc(db, '$:__site-close', ticketId), {
                        ...ticketToClose,
                        status: 'cerrar',
                        closedAt: currentDateTime,
                        createdDate: ticketParent.createdDate,
                        siteName: ticketParent.siteName
                    });

                    if (!this.state.modalStatusTicket) {
                        this.startInterval();
                    }

                } catch (error) {
                    console.error("Error al mover el ticket:", error);
                }
            });
        }
    }
}


  handleSaveStatus = async () => {
    const { selectedTicket, pointStatusTicket } = this.state;
    this.setState({ modalStatusTicket: false, selectedTicket: null, pointStatusTicket: '' },
      async () => {
        await updateDoc(doc(db, '$:__site-tickets', selectedTicket.idTicket), {
          pointMonitor: {
            ticketsNow: [{
              data: selectedTicket.data,
              nameId: selectedTicket.nameId,
              idTicket: selectedTicket.idTicket,
              mptrue: selectedTicket.mptrue,
              totalCost: selectedTicket.totalCost,
              status: pointStatusTicket
            }]
          }
        })
      })
    this.startInterval()

  };

  render() {

    const { filteredTickets } = this.state;
    if (!this.state.pageExist) {
      return <div>Cargando...</div>;
    }

    return (
      <div className='App-header'>
        <div className='container'>
          <Row>
            {filteredTickets && filteredTickets.length > 0 ? (
              filteredTickets.map((ticketObj, k) => {
                const { ticketsNow } = ticketObj.pointMonitor;

                return ticketsNow.map((ticket, index) => (
                  <Col key={`${k}-${index}`} md={4}>
                    <Card className="mb-3" onClick={() => this.handleModalStatus(ticket)}>
                      <Card.Body>
                        <Card.Title>{ticket.nameId}</Card.Title>
                        <CardGroup className="ticket-card">
                          {Object.keys(ticket.data).map((itemKey) => {
                            const item = ticket.data[itemKey];
                            if (Array.isArray(item)) {
                              return item.map((nestedItem, nestedIndex) => (
                                <div key={`${itemKey}-${nestedIndex}`} style={{ fontSize: '0.48em' }}>
                                  <strong>Para:</strong> {nestedItem.name || 'N/A'}<br />
                                  <strong>Item:</strong> {nestedItem.nameItem || 'N/A'}<br />
                                  <strong>Cantidad:</strong> {nestedItem.cuantos || 0}<br />
                                  <strong>Total:</strong> {nestedItem.total || 'N/A'}<br />
                                </div>
                              ));
                            } else {
                              return (
                                <div key={itemKey} style={{ fontSize: '0.48em' }}>
                                  <strong>Item:</strong> {item.name || 'N/A'}<br />
                                  <strong>Cantidad:</strong> {item.clicks || 0}<br />
                                  <strong>Precio:</strong> {item.priceItem || 'N/A'}<br />
                                  <strong>Descripción:</strong> {item.attribute || 'N/A'}<br />
                                </div>
                              );
                            }
                          })}
                        </CardGroup>
                        <Card.Text>
                          <strong>Total Cost:</strong> {ticket.totalCost}
                        </Card.Text>
                        <Card.Text>
                          <strong>Status:</strong>
                          <span style={{ color: this.getStatusColor(ticket.status), fontSize: '0.48em' }}>
                            {ticket.status}
                          </span>
                        </Card.Text>
                        <Card.Footer>
                          <small className="text-muted" style={{ fontSize: '0.48em' }}>ID: {ticket.idTicket}</small>
                        </Card.Footer>
                      </Card.Body>
                    </Card>
                  </Col>
                ));
              })
            ) : (
              <div>No hay tickets disponibles</div>
            )}
          </Row>
          <Offcanvas show={this.state.frameConfirmClose} onHide={this.handleCloseOffcanvas} placement="bottom">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Confirmar Cierre de Ticket</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <p>¿Estás seguro de que deseas cerrar este ticket?</p>
              <Button variant="danger" onClick={this.handleConfirmCloseTicket}>
                Cerrar Ticket
              </Button>
              <Button variant="secondary" onClick={this.handleCloseOffcanvas}>
                Cancelar
              </Button>
            </Offcanvas.Body>
          </Offcanvas>

          <Modal show={this.state.modalStatusTicket} onHide={this.handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Cambiar Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="formStatusSelect">
                <Form.Label>Seleccione el nuevo status</Form.Label>
                <Form.Control
                  as="select"
                  value={this.state.pointStatusTicket}
                  onChange={this.handleChangeStatus}
                >
                  <option value=""></option>
                  <option value="preparando">Preparando</option>
                  <option value="pagado">Pagado</option>
                  <option value="cancelado">Cancelado</option>
                  <option value="cerrar">Cerrar</option>
                </Form.Control>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseModal}>
                Cerrar
              </Button>
              <Button variant="primary" disabled={this.state.secureState} onClick={this.handleSaveStatus}>
                Guardar Cambios
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }

}
