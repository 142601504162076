import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'; 
import { doc, getDoc } from 'firebase/firestore';
import db from '../../firebase';
import { Card, CardBody, CardTitle, Col, Row } from 'react-bootstrap';
import { FaCircleChevronLeft } from 'react-icons/fa6';

Chart.register(...registerables);

export default class FrameCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagados: 0,
      cancelados: 0,
    };
  }

  async componentDidMount() {
    const { pathname } = window.location;
    const cleanedPathname = pathname.startsWith('/admin/')
      ? pathname.substring('/admin/'.length)
      : pathname;

    const monitorStateRef = doc(db, '$:__site-monitor', cleanedPathname);
    const monitorStateSnapshot = await getDoc(monitorStateRef);

    if (monitorStateSnapshot.exists()) {
      const monitorData = monitorStateSnapshot.data();
      const ticketsCC = monitorData.pointMonitor?.aTicketsCC || [];

      let pagados = 0;
      let cancelados = 0;

      ticketsCC.forEach((ticket) => {
        if (ticket.status === 'pagado') {
          pagados++;
        } else if (ticket.status === 'cancelado') {
          cancelados++;
        }
      });

      this.setState({ pagados, cancelados });
    } else {
      console.error('No se encontró el documento en Firebase.');
    }
  }

  handleBackButtonClick = () => {
    if (this.props.onCrossBtn) {
      this.props.onCrossBtn();
    } else {
      console.error("onCrossBtn is not a function");
    }
  };

  render() {
    const { pagados, cancelados } = this.state;

    const data = {
      labels: ['Pagados', 'Cancelados'],
      datasets: [
        {
          label: 'Tickets',
          data: [pagados, cancelados],
          backgroundColor: ['green', 'red'],
        },
      ],
    };

    const options = {
      responsive: true,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };



    return (
      <div>
        <CardBody className='t-center'>
          < FaCircleChevronLeft className=' z-10' onClick={() => { this.handleBackButtonClick() }} />
        </CardBody>
        <Row>
          <Col>
          </Col>
          <Col>
            <Card>
              <CardTitle>Gráfico de Tickets</CardTitle>
              <CardBody>
                <Bar data={data} options={options} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
